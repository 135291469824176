@import "node_modules/ngx-spinner/animations/ball-scale-multiple.css";
@import "node_modules/ngx-spinner/animations/ball-clip-rotate.css";

.spinner-container {
  @apply bg-primary-50 dark:bg-inverse-50;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 3rem;
  border-radius: 50px;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.spinner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  min-height: 150px;
}

.spinner-text{
  @apply text-primary-800 dark:text-inverse-800;
}

::ng-deep .ngx-spinner {
  position: relative !important;
}
