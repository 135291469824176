@layer utilities {
  .snack {
    @apply absolute bottom-10 right-5 z-50;

    .snack-box {
      @apply flex w-full max-w-xs p-3 mb-4 text-gray-500 bg-primary-0 rounded dark:text-gray-300 dark:bg-gray-600 transition-opacity ease-in-out delay-150;

      .icon {
        @apply inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded mt-[0.1rem];
      }

      .message {
        @apply ms-3 text-xs font-normal justify-center inline-flex items-center;
      }

      .close {
        @apply ms-auto -mx-1.5 -my-1.5 bg-primary-0 text-primary-400 hover:text-primary-900 rounded p-1.5 inline-flex items-center justify-center h-8 w-8 dark:text-primary-300 dark:hover:text-primary-0 dark:bg-primary-600 dark:hover:bg-primary-600;
      }

    }
  }
}
