@layer tailwind-base, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@import "assets/scss/global";
@import "assets/scss/auth";
@import "assets/scss/nav";
@import "assets/scss/filters";
@import "assets/scss/toolbar";
@import "assets/scss/grid";
@import "assets/scss/pager";
@import "assets/scss/flags";
@import "assets/scss/spinner";
@import "assets/scss/snack";
@import "assets/scss/promo";
@import "assets/scss/config";
@import "assets/scss/com";
@import "assets/scss/ui";
