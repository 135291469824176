@layer utilities {

  .alert {
    .icon {
      @apply bg-amber-100 dark:bg-opacity-80;
    }

    .action {
      @apply bg-amber-600 hover:bg-amber-300;
    }

    i {
      @apply text-amber-700
    }
  }

  .error {
    .icon {
      @apply bg-red-100 dark:bg-opacity-80;
    }

    .action {
      @apply bg-red-600 hover:bg-red-300;
    }

    i {
      @apply text-red-700
    }
  }

  .info {
    .icon {
      @apply bg-blue-100 dark:bg-opacity-80;
    }

    .action {
      @apply bg-blue-600 hover:bg-blue-300;
    }

    i {
      @apply text-blue-700
    }
  }

  .success {
    .icon {
      @apply bg-green-100 dark:bg-opacity-80;
    }

    .action {
      @apply bg-green-600 hover:bg-green-300;
    }

    i {
      @apply text-green-700
    }
  }
}
