@layer utilities {
  .pager {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-group {
      @apply mx-[0.3rem];
    }

    a {
      @apply no-underline mx-[0.1rem] rounded w-[1.2rem] h-[1.2rem] cursor-pointer text-primary-600 dark:text-inverse-600 bg-primary-100 dark:bg-inverse-100 border border-primary-200 hover:border-primary-400 dark:border-inverse-200 dark:hover:border-inverse-400;

      display: flex;
      justify-content: space-around;
      align-items: center;
      text-decoration-line: none !important;

      &.active {
        @apply border border-primary-400 bg-primary-50 dark:border-inverse-400 dark:bg-inverse-50;
      }
    }

    .nav-group, .page-number {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
