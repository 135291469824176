@layer utilities {
  .toolbar {
    @apply bg-primary-50 dark:bg-inverse-50 pt-0 pr-3 min-w-[1024px] h-10 items-center border-b border-b-primary-200 dark:border-b-inverse-200;

    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;

    .views {
      button {
        @apply flex items-end text-primary-600 dark:text-inverse-600 text-xs mx-[0.25rem] p-[0.25rem] font-light h-10 pb-[6px];

        &.active {
          @apply font-bold border-b-2 border-b-primary-400 dark:border-b-inverse-400 pb-1;
        }
      }
    }

    form {
      @apply my-1 items-baseline w-full bg-primary-100 dark:bg-inverse-100 focus:ring-2 focus:outline-none focus:ring-primary-300 dark:focus:ring-inverse-300 font-light rounded-lg text-xs px-4 py-1 text-center inline-flex;

      input[type='text'] {
        @apply bg-primary-100 dark:bg-inverse-100 border-0 border-primary-300 dark:border-inverse-300 text-primary-900 dark:text-inverse-900 text-xs font-light rounded-sm focus:ring-0 focus:outline-none focus:ring-primary-300 dark:focus:ring-inverse-300 w-full p-1;
      }

      i {
        @apply w-[15px] h-[15px] text-primary-400 dark:text-inverse-400;
      }

      button {
        @apply bg-primary-100 dark:bg-inverse-100 p-0 my-[0.3rem] mx-0 rounded;

        i {
          @apply font-light text-primary-500 dark:text-inverse-500 hover:text-primary-700 dark:hover:text-inverse-700 pt-[0.165rem];
        }
      }
    }
  }

  .actions {
    .subscription{
      @apply rounded-sm py-1 px-1.5 text-xs bg-blue-500 cursor-pointer text-white hover:bg-white hover:text-blue-500 hover:ring-blue-500 hover:ring-1  ;
    }

    button {
      @apply flex align-middle justify-center items-center cursor-pointer text-primary-500 dark:text-inverse-500 bg-primary-50 dark:bg-inverse-50 text-xs mx-[0.25rem] p-[0.25rem] rounded font-light;

      label {
        @apply mx-1 cursor-pointer;
      }
    }

    .dropdown-layer {
      @apply top-[0px] -left-[150px] p-0;

      button {
        @apply bg-transparent p-0 m-0 first:mx-0.5 cursor-pointer;
      }


      .stored-grid-item {
        @apply my-1 items-baseline w-full bg-primary-50 dark:bg-inverse-50 focus:ring-2 focus:outline-none focus:ring-primary-300 dark:focus:ring-inverse-300 font-light text-xs px-4 py-1 text-center inline-flex;

        span {
          @apply ml-2 text-xs font-light cursor-pointer p-1;
        }
      }
    }
  }
}
