@layer utilities {
  .auth {
    @apply text-primary-500 dark:text-inverse-500;

    h1 {
      a {
        @apply bg-logo_black dark:bg-logo_white w-[213px] h-[38px] bg-no-repeat;

        &.compact {
          @apply bg-logo_white_compact bg-no-repeat w-[25px] h-[25px];
        }

        &.pro {
          @apply bg-logo_black_pro dark:bg-logo_black_pro;
        }
      }

      a.logo-sm {
        @apply w-[160px] h-[30px];
      }

      a.pro {
        @apply w-[213px] h-[40px];
      }
    }

    label {
      @apply ml-1 text-xs;
    }

    :not(.password) > input[type='text'], input[type='email'] {
      @apply bg-primary-50 dark:bg-inverse-50 border-primary-300 dark:border-inverse-300 text-primary-900 dark:text-inverse-900 border text-sm rounded w-full p-2.5 focus:ring-1 focus:outline-none focus:ring-primary-300 dark:focus:ring-inverse-300 dark:focus:border-inverse-300;
    }

    .password {
      @apply bg-primary-50 dark:bg-inverse-50 border border-primary-300 dark:border-inverse-300 text-primary-900 dark:text-inverse-900 text-sm rounded w-full p-2;

      input[type='text'], input[type='password'] {
        @apply w-[90%] bg-primary-50 dark:bg-inverse-50 mr-1 my-0 ring-0;
      }

      span {
        @apply cursor-pointer text-primary-400 dark:text-primary-400 mt-1;
      }
    ;
    }

    small {
      @apply ml-1 text-red-400 dark:text-primary-400;
    }

    button {
      @apply bg-primary-800 text-primary-50 dark:bg-inverse-800 dark:text-inverse-50 pr-3 pl-3 pt-2 pb-2 rounded;
    }

    p {
      @apply text-center text-xs mt-5 w-fit;

      a {
        @apply underline cursor-pointer;
      }
    }

    .links {
      @apply text-xs w-full mb-5;
    }
  }
}
