@layer utilities {
  body:not(.dark-mode-preloading) {
    transition: all 0.3s linear;
  }

  /* width */
  ::-webkit-scrollbar {
    @apply w-2 h-1;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-transparent rounded-full
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-primary-300 dark:bg-inverse-300 rounded-full
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-primary-400 dark:bg-inverse-400;
  }

  body {
    @apply bg-primary-50 dark:bg-inverse-50;

    .dropdown-layer {
      @apply absolute z-20 w-[260px] max-h-80 overflow-y-scroll bg-primary-50 dark:bg-inverse-50 border border-primary-200 dark:border-inverse-200 rounded-sm p-3 mt-1 text-primary-600 dark:text-inverse-600;
    }
  }
}
