@layer utilities {
  .config {
    h2, p {
      @apply text-primary-900 dark:text-inverse-900;
    }

    a {
      @apply flex bg-primary-900 dark:bg-inverse-900 text-primary-100 dark:text-inverse-100 text-sm py-2 px-5 rounded-full cursor-pointer;
    }
  }
}
