@layer utilities {
  .single-select {
    @apply flex bg-primary-100 dark:bg-inverse-100 border-0 border-primary-300 dark:border-indigo-300 text-primary-900 dark:text-inverse-900 text-xs font-light rounded-sm focus:ring-0 focus:outline-none focus:ring-primary-300 dark:focus:ring-inverse-300 p-1 mb-1 w-max relative;

    span {
      @apply flex items-center;
    }

    .options {
      @apply flex flex-col align-middle justify-end absolute top-[25px] right-[0] z-[100] bg-primary-100 dark:bg-inverse-100 border border-primary-300 dark:border-inverse-300 text-primary-700 dark:text-inverse-700 text-xs font-light rounded-sm focus:ring-0 focus:outline-none focus:ring-primary-300 dark:focus:ring-inverse-300;

      span {
        @apply flex justify-end p-0.5 m-0.5 cursor-pointer hover:bg-primary-300 dark:hover:bg-inverse-300 rounded-sm;
      }
    }
  }
}
