@layer utilities {
  .nav-container {
    @apply flex flex-col w-[50px] items-center border-r-[0.03rem] border-r-primary-200 dark:border-r-inverse-200 bg-primary-100 dark:bg-inverse-100 overflow-hidden;
    @apply transition-all delay-200 duration-75;

    a {
      @apply cursor-pointer leading-none;
    }

    .logo {
      @apply bg-logo_black_compact dark:bg-logo_white_compact h-[20px] w-[20px] bg-no-repeat bg-left bg-contain;
      @apply transition-all duration-200;
    }

    .promo {
      @apply text-center leading-none content-center rounded-full bg-primary-600 text-primary-50 dark:bg-inverse-600 dark:text-inverse-50 w-9 h-9 text-[0.8rem] hidden opacity-0 pt-1 mb-1 tracking-tighter;
      @apply transition-opacity duration-200;
    }

    .tools {
      @apply opacity-0;
      @apply transition-opacity duration-200;
    }

    .hr {
      @apply mx-[5px] w-[40px];
      @apply transition-all delay-200 duration-75;
    }

    nav {
      @apply flex flex-col items-start justify-items-start text-primary-700 text-[0.85rem] mt-0 w-full py-5 overflow-hidden;

      .item {
        @apply flex items-center pl-2 text-primary-700 dark:text-inverse-700 hover:bg-primary-200 dark:hover:bg-inverse-200 p-3 rounded h-[30px] w-[30px] ml-[10px] mb-4;
        @apply transition-all delay-200 duration-75;

        &:hover {
          i {
            @apply text-primary-500 dark:text-inverse-500;
          }
        }

        &.active {
          @apply bg-primary-200 dark:bg-inverse-200;

          i {
            @apply text-primary-500 dark:text-inverse-500;
          }
        }

        span {
          @apply flex flex-col ml-3 opacity-0;
          @apply transition-opacity delay-0 duration-200;

          &:hover {
            @apply duration-0 delay-0;
          }
        }
      }
    }

    &:hover {
      @apply w-[225px];
      @apply transition-all delay-0 duration-75;

      .hr {
        @apply w-[215px];
        @apply transition-all delay-0 duration-75;
      }

      .promo {
        @apply opacity-100 block;
        @apply transition-opacity delay-100 duration-200;
      }

      .tools {
        @apply opacity-100;
        @apply transition-opacity delay-100 duration-200;
      }

      .logo {
        @apply bg-logo_black dark:bg-logo_white h-[45px] w-[150px] ml-1;
        @apply transition-all delay-100 duration-200;
      }

      .item {
        @apply w-[205px];
        @apply transition-all delay-0 duration-75;

        span {
          @apply opacity-100;
          @apply transition-opacity delay-100 duration-200;
        }
      }
    }

    .user-options {
      @apply absolute -z-[1] bottom-0 flex flex-col  border-t border-t-primary-200 dark:border-inverse-200 p-2 w-full text-primary-500 dark:text-inverse-500;

      i {
        @apply  normal-case p-1;
      }

      span, a {
        @apply leading-none text-xs normal-case cursor-pointer p-1;
      }
    }

    .switcher {
      @apply w-5 h-5 rounded-full flex align-middle items-center justify-center cursor-pointer;

      &.dark {
        @apply text-primary-100 bg-primary-700;
      }

      &.light {
        @apply text-inverse-100 bg-inverse-700;
      }
    }
  }
}

