@layer utilities {
  .filter-nav {
    @apply bg-primary-50 dark:bg-inverse-50 w-full h-10 items-center border-b border-b-primary-200 dark:border-b-inverse-200;

    .views {
      button {
        @apply flex items-end text-primary-600 dark:text-inverse-600 text-xs mx-[0.25rem] p-[0.25rem] font-light h-10 pb-[6px];

        &.active {
          @apply font-bold border-b-2 border-b-primary-400 dark:border-b-inverse-400 pb-1;
        }
      }
    }
  }

  .filter {
    @apply flex flex-col mb-6 text-primary-600 dark:text-inverse-600;

    h2 {
      @apply text-left w-full  pb-2 pl-0 font-bold relative;

      .tooltip-container {
        @apply relative duration-300 transition-opacity invisible opacity-0
      }

      .tooltip {
        @apply absolute w-[160px] ml-2 left-0 -top-[25px] z-[10] flex p-2 text-xxs leading-3 font-normal normal-case text-primary-100 dark:text-inverse-100 bg-primary-700 dark:bg-inverse-700 rounded;

        i {
          @apply absolute -left-[5px] top-[15px] text-xl text-primary-700 dark:text-inverse-700;
        }
      }
    }

    h3 {
      @apply text-left w-full text-primary-600 dark:text-inverse-600 mb-2;
    }

    form {
      @apply w-full p-0;
    }

    .filter-action {
      @apply justify-center text-primary-100 bg-primary-700 hover:bg-primary-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 cursor-pointer;
    }
  }

  .form {
    input[type='text'] {
      @apply block w-full bg-primary-100 dark:bg-inverse-100 border-0 border-primary-300 dark:border-inverse-300 text-primary-900 dark:text-inverse-900 text-xs font-light rounded p-2;
    }

    label {
      @apply w-fit bg-transparent text-xs text-primary-400 dark:text-inverse-400 font-light px-2 top-2 z-10;

      &.error {
        @apply text-red-500 dark:text-red-600;
      }
    }

    .float-label {
      label {
        @apply absolute;
      }

      select {
        @apply bg-primary-100 text-primary-400 dark:bg-inverse-100 dark:text-inverse-400;
      }
    }
  }

  .keyword {
    @apply mb-2;

    textarea {
      @apply w-full border-0 bg-primary-100 dark:bg-inverse-100 text-primary-900 dark:text-inverse-900 focus:ring-0 focus:outline-none font-light rounded text-xs p-2;
    }
  }

  .dropdown-search {
    @apply mb-2;

    form {
      @apply w-full bg-primary-100 dark:bg-inverse-100 focus:ring-2 focus:outline-none focus:ring-primary-300 dark:focus:ring-inverse-300 font-light rounded-lg text-xs px-4 py-1 text-center inline-flex items-center;

      i {
        @apply w-3 h-3 text-primary-400 dark:text-inverse-400;

      }

      input[type='text'] {
        @apply bg-primary-100 dark:bg-inverse-100 border-0 border-primary-300 dark:border-indigo-300 text-primary-900 dark:text-inverse-900 text-xs font-light rounded-sm focus:ring-0 focus:outline-none focus:ring-primary-300 dark:focus:ring-inverse-300 w-full p-1;
      }

      span {
        @apply text-primary-500 dark:text-inverse-500 cursor-pointer;
      }
    }

    .dropdown-layer {
      @apply top-auto -left-[130px] max-h-[500px];

      span {
        @apply block mb-1 text-xs font-medium text-primary-900 dark:text-inverse-900 cursor-pointer
      }

      input[type='checkbox'] {
        @apply w-4 h-4 accent-primary-200 dark:accent-inverse-200 checked:bg-primary-500 dark:checked:bg-inverse-500 border-primary-300 dark:border-inverse-300 focus:ring-primary-500 dark:focus:ring-inverse-500 rounded;
      }

      label {
        @apply ml-2 text-xs font-light;
      }
    }

    .pill {
      @apply inline-flex items-center p-1 me-1 mt-1 text-xxs font-extralight text-primary-800 dark:text-inverse-800 bg-primary-100 dark:bg-inverse-100 rounded;

      button {
        @apply inline-flex items-center px-1 py-0 ms-1 text-xs text-primary-400 dark:text-inverse-400 bg-transparent rounded-sm hover:bg-primary-200 dark:hover:bg-inverse-200 hover:text-primary-900 dark:hover:text-inverse-900;
      }
    }

    .extra {
      @apply bg-primary-600 dark:bg-inverse-600 text-primary-50 dark:text-inverse-50 rounded p-1 text-xxs cursor-pointer;
    }
  }

  .balloon-box {
    .pill {
      @apply inline-flex items-center p-1 me-1 mt-1 text-xxs font-extralight text-primary-800 dark:text-inverse-800 bg-primary-100 dark:bg-inverse-100 rounded;

      button {
        @apply inline-flex items-center px-1 py-0 ms-1 text-xs text-primary-400 dark:text-inverse-400 bg-transparent rounded-sm hover:bg-primary-200 dark:hover:bg-inverse-200 hover:text-primary-900 dark:hover:text-inverse-900;
      }
    }

    .extra {
      @apply bg-primary-600 dark:bg-inverse-600 text-primary-50 dark:text-inverse-50 rounded p-1 text-xxs cursor-pointer;
    }
  }
}
