[hidden] {
  display: none !important;
}

.scrollable {
  height: 100vh;
  overflow-y: auto;
}

.virtualScrollView {
  height: 89vh;
  overflow-y: hidden;
}

.grid-summary {
  @apply bg-primary-50 dark:bg-inverse-50 w-full;

  .box {
    @apply min-w-[1024px]
  }

  span {
    @apply text-primary-400 dark:text-inverse-400 text-xxs py-2;

    a {
      @apply underline cursor-pointer;
    }
  }
}

.data-grid {
  @apply flex flex-col;

  @apply border-b border-b-primary-200 dark:border-b-inverse-200;

  .row {
    @apply flex;

    &.sticky-top, .sticky-top {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 10;
    }

    &.sticky-bottom, .sticky-bottom {
      position: sticky;
      bottom: 0;
      left: 0;
      z-index: 10;
    }

    &.sticky-left, .sticky-left {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 5;
    }

    &:nth-child(even) {
      .cell {
        @apply bg-primary-100 dark:bg-inverse-100;
      }
    }

    &:hover {
      .cell:not(.header, .summary) {
        @apply bg-primary-300 dark:bg-inverse-300;
      }
    }
  }

  .summary {
    .foot-label {
      position: absolute;
      left: 35px;

      @apply font-bold;
    }
  }

  .header {
    flex-direction: column;
    justify-content: flex-end;

    @apply bg-primary-50 dark:bg-inverse-50 text-primary-800 dark:text-inverse-800;

    .sort {
      @apply leading-none;

      i {
        @apply ml-1 text-gray-400;
      }
    }

    .header-main {
      padding: 3px 0 3px 0;
      display: flex;
      justify-content: center;

      .header-text {
        padding-top: 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @apply mb-0.5;

        strong {
          display: flex;
          justify-content: center;

          @apply leading-tight font-medium;
        }
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;

        &.company, &.verticals, &.target, &.buyer, &.target-verticals, &.target-overview, &.deal-overview, &.target-city, &.financial-sponsor, &.buyer-type, &.buyer-overview {
          justify-content: left;
        }
      }
    }

    .header-sub {
      display: flex;
      justify-content: center;

      @apply text-[0.72rem];

      > span {
        display: flex;
        justify-content: center;
      }

      span {
        @apply font-light text-primary-400 dark:text-inverse-400 w-fit;
      }
    }
  }

  .cell {
    @apply flex text-[0.72rem] text-primary-800 dark:text-inverse-800 border-r border-r-primary-200 dark:border-r-inverse-200 border-b border-b-primary-200 dark:border-b-inverse-200 bg-primary-50 dark:bg-inverse-50 px-1;

    &.hidden {
      opacity: 0.33;
    }

    &.blind {
      display: none;
    }
  }

  .data {
    display: flex;
    align-items: center;
    align-content: flex-start;
    height: 28px;

    .block {
      display: flex;
      align-items: center;

      @apply font-normal;

      span {
        @apply w-full;
      }
    }

    .short_string {
      @apply text-primary-400 dark:text-inverse-400 text-[0.55rem] tracking-tight;
    }

    .number, .long_number, .decimal, .year, .short_date, .long_date {
      display: flex;
      justify-content: center;
    }

    .image {
      justify-content: center;

      img {
        height: 13px;
        width: auto;
      }
    }

    .link {
      @apply text-xxs;
    }

    .icon {
      display: flex;
      justify-content: center;

      span {
        @apply text-sm rounded-[0.1rem] h-[1rem] w-[1rem];
      }
    }

    .list, .text_list {
      display: flex;
      align-items: center;
      justify-content: left;

      .pill {
        @apply relative text-xxs bg-primary-200 dark:bg-inverse-200 py-[0.2rem] px-[0.3rem] m-1 text-primary-700 dark:text-inverse-700 leading-none h-fit;
        display: flex;
        align-items: center;
        border-radius: 0.2rem;
        white-space: nowrap;
        z-index: 1;

        .short, .single {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .pill:has(.short) {
        max-width: 5.2rem;
      }

      .pill:has(.single) {
        max-width: 12rem;
      }

      .extra {
        @apply text-primary-700 dark:text-inverse-700 bg-primary-200 dark:bg-inverse-200 text-xxs h-fit w-fit cursor-pointer px-1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.2rem;
      }

      .extended {
        @apply relative z-10 bg-primary-50 dark:bg-inverse-50 cursor-pointer;
        border-radius: 0.2rem;

        .pill {
          max-width: 100%;
        }
      }
    }

    .text_list {
      .pill {
        @apply bg-transparent text-[0.72rem];
      }
    }

    .text {
      mul-cell-text {
        display: flex;
        flex-wrap: wrap;
        align-content: baseline;
        place-content: center;

        .long-text {
          max-width: 320px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 2px;
        }
      }
    }
  }
}

.floating-box {
  @apply rounded absolute bg-gray-50 dark:bg-inverse-50 border-2 dark:border-inverse-400;

  z-index: 1000;
  width: 350px;
  text-align: left;
  font-size: 12px;
  padding: 10px;
  margin: 0;
}

.summary {
  @apply border-t border-t-primary-300 dark:border-t-inverse-300 bg-primary-400 dark:bg-inverse-400;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    @apply font-bold bg-transparent;
    display: flex;
    justify-content: center;
  }
}
